import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages,  Flex, FlexContent, TitleServices, ListServices} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"



import SEO from "../components/seo"

const dermatologia = () => {
  return (

    <>

    <ThemeProvider theme={theme}>
    <SEO 
    title="Dermatologia"
    description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący zLeczenie zakażeń grzybiczych, bakteryjnych i pasożytniczych oraz tzw. chorób autoagresywnych psów, kotów, zwierząt futerkowych i gryzoni. 
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords="dermatologia zwierząt kalisz, choroby skóry, zwierzęta, kalisz"
    />
    
    <Navbar />
    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Dermatologia</BigTitlePages>  

           <FlexContent>
         
          <UslugiPages />
 <Flex>

 <StaticImage
    src="../images/dermatologia.jpeg"
    alt="Weterynarz Kalisz dermatolog"
    width={275}
    placeholder="blurred"
    className='staticimagepages'
    />

    <BoxTextPages>

<TitleServices>Usługi z zakresu dermatolgii</TitleServices>
<ListServices> Pełne badania diagnostyczne skóry (bakteriologia, badanie zeskrobin)</ListServices>
<ListServices> Terapia atopii i alergii</ListServices>
<ListServices> Leczenie zakażeń grzybiczych, bakteryjnych i pasożytniczych (nużyca, świerzb) oraz tzw. chorób autoagresywnych</ListServices>



    </BoxTextPages>

 
    
</Flex>
</FlexContent>

<Footer />
</ThemeProvider>
    </>
  )
}

export default dermatologia